<template>
  <div class="hello">
    <transition name="el-zoom-in-center">
      <el-table v-if="showTable"
        :data="list"
        stripe
        border
        style="width: 100%">
        <el-table-column
          prop="task_tag"
          label="任务标签"
          width="150">
        </el-table-column>
        <el-table-column
          prop="triggle_date"
          label="任务时间"
          :formatter="dateFormat"
          width="100">
        </el-table-column>
        <el-table-column
          prop="content"
          label="任务内容">
        </el-table-column>
        <el-table-column
          prop="key_word"
          label="任务关键词"
          width="220">
        </el-table-column>
        <el-table-column
          prop="button_name"
          label="按钮文案"
          width="140">
        </el-table-column>
        <el-table-column
          prop="tips_male"
          label="tips男">
        </el-table-column>
        <el-table-column
          prop="tips_female"
          label="tips女">
        </el-table-column>
      </el-table>
    </transition>
  </div>
</template>

<script>
import {taskList, taskCreation, taskModification, taskDeletion} from '@/api/loveTask.js';
import moment from 'moment';

export default {
  name: 'Reserve',
  props: {

  },
  data () {
    return {
      showTable: false,
      showForm: false,
      list: []
    };
  },

  methods: {
    dateFormat (row, column) {
      let date = row[column.property];
      if (date == undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD');
    },

    getListData () {
      let that = this;
      taskList({type: 'constant'})
        .then((res) => {
          console.log('每日任务列表：', res);
          that.list = res.data;
          that.showTable = true;
        })
        .catch((error) => {

        });
    }
  },

  beforeCreate () {},
  created () {
    this.getListData();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
