import httpRequest from '@/utils/network/request';

// 任务列表
export const taskList = function (params) {
  const url = '/love/task/list';
  return httpRequest.get(url, params);
};

// 任务增加
export const taskCreation = function (params) {
  const url = '/love/task/creation';
  return httpRequest.post(url, params);
};

// 任务修改
export const taskModification = function (params) {
  const url = '/love/task/modification';
  return httpRequest.post(url, params);
};

// 任务删除
export const taskDeletion = function (params) {
  const url = '/love/task/deletion';
  return httpRequest.post(url, params);
};